import { Button, Col, DatePicker, Form, Input, Modal, Result, Row, Select, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { openNotification } from "../../../components/Notification";
import moment from "moment";
import { DATA_HORA_FORMATO, urls } from "../../../utils";
import { PesquisaPessoaModal } from "../../pessoa/PesquisaPessoaModal";
import { FormularioPessoa } from "../../pessoa/componentes/FormularioPessoa";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { tipoAtendimentoService } from "../../../services/resources/tipoAtendimentoService";
import { canalService } from "../../../services/resources/canalService";
import { canalAtendimentoService } from "../../../services/resources/canalAtendimentoService";
import { atendimentoService } from "../../../services/resources/atendimentoService";
import { ClusterOutlined, DeleteOutlined, SearchOutlined } from "@ant-design/icons";

export const FormularioAtendimento = ({ onView, onEdit, onCreate }) => {


    const history = useHistory();
    const [atendimentoForm] = Form.useForm();

    const [canais, setCanais] = useState([]);
    const [canaisAtendimento, setCanaisAtendimento] = useState([]);

    const [pessoas, setPessoas] = useState([]);
    const [pessoasForm, setPessoasForm] = useState([]);

    const [tiposAtendimento, setTiposAtendimento] = useState(null);
    const [subtiposAtendimento, setSubtiposAtendimento] = useState(null);


    const [pesquisaPessoaModal, setPesquisaPessoaModal] = useState(false);
    const [cadastroPessoaModal, setCadastroPessoaModal] = useState(false);
    const [sucessoModal, setSucessoModal] = useState(false);

    const [protocolo, setProtocolo] = useState('');

    const [loading, setLoading] = useState({
        canais: false,
        tiposAtendimento: false,
        subtiposAtendimento: false,
        submit: false,
    });

    const getCanais = async () => {
        try {
            const response = await canalService.list();

            setCanais(response?.data)
            
            if (onCreate) {
                const canal = response?.data?.find((canal) => canal.sistema);
                atendimentoForm.setFieldsValue({
                    canal: canal,
                });

                changeCanal(canal.codigo);
            }
        } catch (error) {
          openNotification(
            "error",
            <strong>Ocorreu um erro ao buscar os canais!</strong>,
            `${error?.response?.data?.detail}`
          );
        }
    };

    async function onSearch() {
        setPesquisaPessoaModal(true);
    }

    async function getTiposAtendimento() {
        setLoading({ ...loading, tiposAtendimento: true });

        try {
            const response = await tipoAtendimentoService.listarTiposAtendimento();
            setTiposAtendimento(response?.data);
        } catch (error) {
            openNotification(
                "error",
                <strong>Ocorreu um erro ao buscar os tipos de atendimento!</strong>,
                `${error?.response?.data?.detail}`
              );
        } finally {
            setLoading({ ...loading, tiposAtendimento: false });
        }
    }

    useEffect(() => {
        getCanais();
        getTiposAtendimento();

        atendimentoForm.setFieldsValue({
            dataAtendimento: moment(new Date(), DATA_HORA_FORMATO),
        });
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const handleGetPessoa = (pessoa) => {
        if (!pessoas.some((p) => p.codigo === pessoa.codigo)) {
            setPessoas([...pessoas, pessoa]);
            setPessoasForm([...pessoasForm, {codigo: pessoa.codigo}]);
            atendimentoForm.setFieldValue("pessoas", [...pessoasForm, {codigo: pessoa.codigo}]);
        }
        
        setPesquisaPessoaModal(false);
        setCadastroPessoaModal(false);
      }

      async function changeCanal(codigoCanal) {
        try {
            const response = await canalAtendimentoService.listarPorCanal(codigoCanal);
            setCanaisAtendimento(response?.data);
        } catch (error) {
          openNotification(
            "error",
            <strong>Ocorreu um erro ao buscar os canais de atendimento!</strong>,
            `${error?.response?.data?.detail}`
          );
        }
      }

      async function changeTipoAtendimento(codigoTipoAtendimento) {
        setLoading({ ...loading, tiposAtendimento: true });

        try {
            const response = await tipoAtendimentoService.listarSubtiposAtendimentoPorTipos(codigoTipoAtendimento);
            setSubtiposAtendimento(response?.data);
        } catch (error) {
            openNotification(
                "error",
                <strong>Ocorreu um erro ao buscar os tipos de atendimento!</strong>,
                `${error?.response?.data?.detail}`
              );
        } finally {
            setLoading({ ...loading, tiposAtendimento: false });
        }
      }

      async function handleSubmit() {
        setLoading({ ...loading, submit: true });

        try {
            const response = await atendimentoService.adicionar(atendimentoForm.getFieldsValue());
            
            setProtocolo(response?.data?.protocolo);

            atendimentoForm.resetFields();
            setPessoas([]);

            setSucessoModal(true);
            
        } catch (error) {
            openNotification(
                "error",
                <strong>Ocorreu um erro ao tentar cadastrar o atendimento!</strong>,
                `${error?.response?.data?.detail}`
            );
        } finally {
            setLoading({ ...loading, submit: false });
        }
      }

    return (
        <>
            <Form 
                form={atendimentoForm} 
                name="atendimento" 
                layout="vertical" 
                onFinish={handleSubmit}
            >                    
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                            name={["canal", "codigo"]}
                            label={<span>Secretaria:</span>}
                            rules={[
                                { required: true, message: "Selecionar uma secretaria é obrigatório!" },
                            ]}
                        >
                            <Select
                                placeholder="Selecione a secretaria"
                                autoComplete="none"
                                disabled={onView || onCreate}
                                allowClear
                                showArrow={onView || onCreate}
                                showSearch
                                onChange={(canal) => changeCanal(canal)}
                                filterOption={(input, option) =>
                                    option?.search?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                }
                                options={canais?.map(({ sigla, codigo, sistema }) => ({
                                    label: (
                                    <>
                                        {sistema && <ClusterOutlined />} {sigla}
                                    </>
                                    ),
                                    value: codigo,
                                    search: sigla,
                                }))}
                            />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                            name={["canalAtendimento", "codigo"]}
                            label={<span>Canal atendimento:</span>}
                            rules={[
                                { required: true, message: "Selecionar um canal de atendimento é obrigatório!" },
                            ]}
                        >
                            <Select
                                placeholder="Selecione o canal do atendimento"
                                autoComplete="none"
                                allowClear
                                showArrow={onView || onCreate}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.search?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                }
                                options={canaisAtendimento?.map(({ codigo, sigla }) => ({
                                    label: sigla,
                                    value: codigo,
                                }))}
                            />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                            name="protocolo"
                            label={<span>Protocolo do atendimento:</span>}
                        >
                            <Input
                                disabled
                                placeholder="O protocolo será gerado automaticamente"  
                            />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                            name="dataAtendimento"
                            label={<span>Data do atendimento:</span>}
                        >
                            <DatePicker
                                style={{ width: "100%" }}
                                format="DD/MM/YYYY HH:mm"
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 15 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item>
                            <Button
                                type="primary"
                                icon={<SearchOutlined/>}
                                onClick={onSearch}
                            >
                                Pesquisar pessoa
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: 15 }}>
                  
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item name="pessoas" hidden/>
                        <Form.Item name="pessoas" rules={[{ required: true, message: "Selecione pelo menos uma pessoa para o atendimento é obrigatório!" }]}>

                            <Table
                                size="small"
                                pagination={false}
                                bordered
                                columns={[{
                                    title: <strong>Nome</strong>,
                                    dataIndex: "nome",
                                    key: "nome",
                                    render: (text) => (
                                        <Highlighter
                                            highlightStyle={{
                                            backgroundColor: "#ff9945",
                                            padding: 0,
                                            color: "#fff",
                                            }}
                                            searchWords={[text?.nome]}
                                            autoEscape
                                            textToHighlight={text ? text.toString() : ""}
                                        />
                                        ),
                                    },
                                    {
                                        title: <strong>Ações</strong>,
                                        key: "acao",
                                        fixed: "right",
                                        width: 64,
                                        render: ({ codigo }) => (
                                            <Space size="small">
                                                <Button
                                                    className="action-button"
                                                    type="danger"
                                                    title="Remover pessoa do atendimento"
                                                    onClick={() => {
                                                        setPessoas(pessoas.filter(p => p.codigo !== codigo));
                                                        if (pessoas.length === 0) {
                                                            atendimentoForm.setFieldValue("pessoas", []);
                                                        }
                                                    }}
                                                >
                                                    <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                                                </Button>
                                            </Space>
                                        ),
                                    },
                                ]}
                                dataSource={pessoas}
                            />

                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            name={["tipoAtendimento", "codigo"]}
                            label={<span>Tipo de atendimento:</span>}
                            rules={[{ required: true, message: "Selecionar um tipo para o atendimento é obrigatório!" }]}
                        >
                            <Select
                                placeholder="Selecione um tipo para o atendimento"
                                allowClear
                                autoComplete="none"
                                disabled={onView}
                                showSearch
                                onChange={(tipoAtendimento) => changeTipoAtendimento(tipoAtendimento)}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={tiposAtendimento?.map(({ codigo, descricao }) => ({
                                    label: descricao,
                                    value: codigo,
                                }))}
                            />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            name={["subtipoAtendimento", "codigo"]}
                            label={<span>Subtipo de atendimento:</span>}
                            rules={[{ required: true, message: "Selecionar um subtipo para o atendimento é obrigatório!" }]}
                        >
                            <Select
                                placeholder="Selecione um subtipo para o atendimento atendimento"
                                allowClear
                                autoComplete="none"
                                disabled={onView}
                                showSearch
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={subtiposAtendimento?.map(({ codigo, descricao }) => ({
                                    label: descricao,
                                    value: codigo,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            name={"descricao"}
                            label={<span>Descrição:</span>}
                            rules={[{ required: true, message: "Escrever a descrição do atendimento é obrigatório!" }]}
                        >
                            <Input.TextArea
                                autoSize={{ minRows:6 }}
                                placeholder="Escreva a descrição do atendimento"
                                disabled={onView}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            name={"observacao"}
                            label={<span>Observações:</span>}
                        >
                            <Input.TextArea
                                autoSize={{ minRows:6 }}
                                placeholder="Escreva alguma observação que queira adicionar ao atendimento"
                                disabled={onView}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="end" style={{ marginTop: "1rem" }}>
                    <Button htmlType="submit" type="primary" loading={loading.submit}>
                        cadastrar
                    </Button>
                </Row>
            </Form>

            <Modal
                title={
                    <Row justify="space-between" align="middle">
                        <strong>Escolha uma pessoa para ser atendida</strong>
                        <Button
                            type="primary"
                            onClick={() => {
                                setPesquisaPessoaModal(false);
                                setCadastroPessoaModal(true);
                            }}
                            >
                            cadastrar pessoa
                        </Button>
                    </Row>
                }
                width="90vw"
                bodyStyle={{
                    height: "70vh",
                    overflowY: "auto",
                    display: "flex",
                    gap: "2rem",
                    flexDirection: "column",
                }}
                closable={false}
                centered
                destroyOnClose
                visible={pesquisaPessoaModal}
                onCancel={() => {setPesquisaPessoaModal(false)}}
                footer={
                    <Button
                      key="back"
                      type="danger"
                      onClick={() => setPesquisaPessoaModal(false)}
                    >
                      cancelar
                    </Button>
                }
            >
                <PesquisaPessoaModal getPessoa={(pessoa) => handleGetPessoa(pessoa)}/>
            </Modal>

            <Modal
                title="Cadastrar pessoa"
                width="90vw"
                bodyStyle={{
                    height: "70vh",
                    overflowY: "auto",
                    display: "flex",
                    gap: "2rem",
                    flexDirection: "column",
                }}
                closable={false}
                centered
                destroyOnClose
                visible={cadastroPessoaModal}
                onCancel={() => {setCadastroPessoaModal(false)}}
                footer={
                    <Button
                      key="back"
                      type="danger"
                      onClick={() => setCadastroPessoaModal(false)}
                    >
                      cancelar
                    </Button>
                }
            >
                <FormularioPessoa onCreate={true} afterCreate={(pessoa) => handleGetPessoa(pessoa)}/>
            </Modal>

            <Modal
                width="40vw"
                bodyStyle={{
                    overflowY: "auto",
                    display: "flex",
                    gap: "2rem",
                    flexDirection: "column",
                }}
                closable={false}
                centered
                destroyOnClose
                visible={sucessoModal}
                footer={null}
            >
                <Result
                    status="success"
                    title="Atendimento cadastrada com sucesso!"
                    subTitle={<p>O atendimento com protocolo <strong>{protocolo}</strong> foi cadastrada com sucesso e está em análise.</p>}
                    extra={[
                        <Button 
                            key="pesquisa"
                            onClick={() => {
                                history.push(urls.PESQUISA_ATENDIMENTO);
                                setSucessoModal(false); 
                            }}
                        >
                          pesquisa
                        </Button>,
                        <Button 
                            type="primary" 
                            key="novo" 
                            style={{ width: 90 }}
                            onClick={() => {
                                atendimentoForm.setFieldsValue({
                                    dataAtendimento: moment(new Date(), DATA_HORA_FORMATO),
                                });
                                atendimentoForm.setFieldsValue({
                                    canal: canais?.find((canal) => canal.sistema),
                                });
                                setSucessoModal(false);
                            }}
                        >
                            novo
                        </Button>,
                      ]}
                />
            </Modal>
        </>
    );
  };