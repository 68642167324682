import React from "react";

import { urls } from "../../utils";
import { TabelaAtendimento } from "./componentes/TabelaAtendimento";
import { BreadcrumbComponent } from "./Breadcrumb";
import { HeaderComponent } from "./Header";
import { InfoHeaderHoraComponent } from "./InfoHeaderHoraComponent";


export const PesquisaAtendimento = () => {

  return (
    <div id="sectionMain">
      <BreadcrumbComponent
        descricaoRecurso="atendimento"
        urlPesquisa={urls.PESQUISA_ATENDIMENTO}
      />

      <div className="container-section">
        <div id="header-container">
          <HeaderComponent 
            titulo="Lista de atendimentos"
            urlCadastro={urls.CADASTRO_ATENDIMENTO}
            infoHeader={<InfoHeaderHoraComponent/>}
            tituloButao="novo atendimento"
          />
        </div>

        <TabelaAtendimento/>
      </div>
    </div>
  );
};