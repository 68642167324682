import { urls } from "../../utils";
import { BreadcrumbComponent } from "./Breadcrumb";
import { HeaderComponent } from "./Header";
import { FormularioAtendimento } from "./componentes/FormularioAtendimento";

export const CadastroAtendimento = () => {

    return (
        <div id="sectionMain">
          <BreadcrumbComponent
            onCreate="true"
            onEdit=""
            onView=""
            descricaoRecurso="atendimento"
            urlPesquisa={urls.PESQUISA_ATENDIMENTO}
            urlCadastro={urls.CADASTRO_ATENDIMENTO}
            urlEditar=""
            urlHome=""
            descricaoView="#OISOLAT_202406030001"
          />
          
          <div className="container-section">
            <div id="header-container">
              <HeaderComponent 
                titulo="Cadastrar atendimento"
                infoHeader={<span>Inserir informações sobre o atendimento</span>}
              />
            </div>
            <FormularioAtendimento onCreate={true}/>
          </div>
        </div>
      );
};