export const STATUS = Object.freeze({
  ANALISAR: 'A ANALISAR',
  ABERTO: 'EM ABERTO',
  ANDAMENTO: 'EM ANDAMENTO',
  FINALIZADO: 'FINALIZADA',
  VALIDAR: 'A VALIDAR',
  ARQUIVADA: 'ARQUIVADA',
});

export const USER_ROLES = Object.freeze({

  VISUALIZAR_OPERADOR_DENUNCIA: 'VISUALIZAR_OPERADOR_DENUNCIA',

  CONSULTAR_TODAS_DENUNCIAS: 'CONSULTAR_TODAS_DENUNCIAS',
  CONSULTAR_TODAS_DENUNCIAS_MUNICIPIO: 'CONSULTAR_TODAS_DENUNCIAS_MUNICIPIO',
  ALTERAR_TODAS_DENUNCIAS: 'ALTERAR_TODAS_DENUNCIAS',
  INCLUIR_DENUNCIA: 'INCLUIR_DENUNCIA',
  INCLUIR_DENUNCIA_INSTITUICAO: 'INCLUIR_DENUNCIA_INSTITUICAO',
  ARQUIVAR_DENUNCIA: 'ARQUIVAR_DENUNCIA',
  CONSULTAR_DETALHE_DENUNCIA: 'CONSULTAR_DETALHE_DENUNCIA',
  DELETAR_ANEXO_DENUNCIA: 'DELETAR_ANEXO_DENUNCIA',
  
  CONSULTAR_DENUNCIAS_RELACIONADA_PESSOA: 'CONSULTAR_DENUNCIAS_RELACIONADA_PESSOA',

  CONSULTAR_TODAS_INSTITUICOES: 'CONSULTAR_TODAS_INSTITUICOES',
  GERENCIAR_INSTITUICOES: 'GERENCIAR_INSTITUICOES',
  ALTERAR_DENUNCIAS_INSTITUICAO: 'ALTERAR_DENUNCIAS_INSTITUICAO',

  DELETAR_ENCAMINHAMENTO_INSTITUICAO: 'DELETAR_ENCAMINHAMENTO_INSTITUICAO',
  CONSULTAR_ENCAMINHAMENTOS_INSTITUICAO: 'CONSULTAR_ENCAMINHAMENTOS_INSTITUICAO',
  
  INCLUIR_ENCAMINHAMENTOS: 'INCLUIR_ENCAMINHAMENTOS',
  CONSULTAR_DETALHE_ENCAMINHAMENTO: 'CONSULTAR_DETALHE_ENCAMINHAMENTO',
  CONSULTAR_PROGRESSO_ENCAMINHAMENTO: 'CONSULTAR_PROGRESSO_ENCAMINHAMENTO',
  CONSULTAR_TODOS_ENCAMINHAMENTOS: 'CONSULTAR_TODOS_ENCAMINHAMENTOS',
  ALTERAR_TODOS_ENCAMINHAMENTOS: 'ALTERAR_TODOS_ENCAMINHAMENTOS',
  ALTERAR_ENCAMINHAMENTO_INSTITUICAO: 'ALTERAR_ENCAMINHAMENTO_INSTITUICAO',
  DELETAR_ANEXO_ENCAMINHAMENTO: 'DELETAR_ANEXO_ENCAMINHAMENTO',

  GERENCIAR_PESSOA: 'GERENCIAR_PESSOA',
  CONSULTAR_DETALHE_PESSOA: 'CONSULTAR_DETALHE_PESSOA',
  CADASTRAR_PESSOA: 'CADASTRAR_PESSOA',
  ALTERAR_PESSOA: 'ALTERAR_PESSOA',
  DELETAR_PESSOA: 'DELETAR_PESSOA',
  CONSULTAR_PESSOAS: 'CONSULTAR_PESSOAS',

  CADASTRAR_ATENDIMENTO: 'CADASTRAR_ATENDIMENTO',
  EDITAR_ATENDIMENTO: 'ALTERAR_ATENDIMENTO',
  DELETAR_ATENDIMENTO: 'DELETAR_ATENDIMENTO',
  CONSULTAR_ATENDIMENTOS: 'CONSULTAR_ATENDIMENTOS',
  
  DELETAR_OBSERVACAO_PUBLICA: 'DELETAR_OBSERVACAO_PUBLICA',

  GERENCIAR_USUARIOS: 'GERENCIAR_USUARIOS',

  GERENCIAR_CADASTROS_BASICOS: 'GERENCIAR_CADASTROS_BASICOS',

  CONSULTAR_ESTATISTICAS: 'CONSULTAR_ESTATISTICAS',

  CONSULTAR_PAINEIS_GERENCIAIS: 'CONSULTAR_PAINEIS_GERENCIAIS',
  CONSULTAR_PAINEIS_CASA_CRIANCA_ADOLSCENTE: 'CONSULTAR_PAINEIS_CASA_CRIANCA_ADOLSCENTE',
  CONSULTAR_PAINEIS_CEMARIS: 'CONSULTAR_PAINEIS_CEMARIS',
  CONSULTAR_PAINEL_CRDH: 'CONSULTAR_PAINEL_CRDH',
  CONSULTAR_PAINEL_FUNCI: 'CONSULTAR_PAINEL_FUNCI',
  CONSULTAR_PAINEL_CERLGBT: 'CONSULTAR_PAINEL_CERLGBT',


  CADASTRAR_OBSERVACAO_PUBLICA: 'CADASTRAR_OBSERVACAO_PUBLICA',

  CLASSIFICACAO_DEVOLUTIVA_REDE: 'CLASSIFICACAO_DEVOLUTIVA_REDE',
  VISUALIZAR_CLASSIFICACAO_DEVOLUTIVA_REDE: 'VISUALIZAR_CLASSIFICACAO_DEVOLUTIVA_REDE'

});

export const urls = {

  LOGIN: '/login',
  DENUNCIAS_PUBLICA: 'https://oisol.sps.ce.gov.br/consulta',
  DENUNCIAS_LIST: '/denuncias',
  DENUNCIAS_GERAL_LIST: '/denuncias/geral',
  DENUNCIAS_MUNICIPIO: '/denuncias/municipio',

  DENUNCIA_CRIAR: '/denuncias/criar',
  DENUNCIA_DETAILS: '/denuncias/:codigo',
  DENUNCIA_EDITAR: '/denuncias/:codigo/editar',

  DENUNCIA_ANEXO: '/denuncias/:codigo/anexos',

  ENCAMINHAMENTOS_LIST: '/encaminhamentos',
  ENCAMINHAMENTOS_RECEBIDOS: '/encaminhamentos/recebidos',
  ENCAMINHAMENTOS_ENVIADOS: '/encaminhamentos/enviados',
  ENCAMINHAMENTO_DETAILS: '/encaminhamentos/:codigo',
  ENCAMINHAMENTO_CRIAR: '/denuncias/:codigo/encaminhamentos/criar',

  ENCAMINHAMENTO_ANEXO: '/encaminhamentos/:codigo/anexos',

  PESSOAS_LIST: '/pessoas',
  PESSOAS_CRIAR: '/pessoas/criar',
  PESSOAS_DETAILS: '/pessoas/:codigo',
  PESSOAS_EDITAR: '/pessoas/:codigo/editar',


  PESQUISA_ATENDIMENTO: '/atendimentos',
  CADASTRO_ATENDIMENTO: '/atendimentos/criar',
  EDITAR_ATENDIMENTO: '/atendimentos/:codigo/editar',
  VISUALIZAR_ATENDIMENTO: '/atendimentos/:codigo',

  USUARIOS_LIST: '/usuarios',
  USUARIOS_CRIAR: '/usuarios/criar',
  USUARIOS_DETAILS: '/usuarios/:codigo',
  USUARIOS_EDITAR: '/usuarios/:codigo/editar',
  USUARIOS_TERMO: '/usuarios/:codigo/termo',

  GRUPOS_LIST: '/grupos',
  GRUPOS_CRIAR: '/grupos/criar',
  GRUPOS_DETAILS: '/grupos/:codigo',
  GRUPOS_EDITAR: '/grupos/:codigo/editar',

  CADASTROS_BASICOS: '/cadastros',
  CLASSIFICACOES_ATRIBUTOS: '/classificacaoAtributos',
  CLASSIFICACOES: '/classificacoes',
  CLASSIFICACOES_TEMA_DETAILS: '/temas/:codigo/editar',
  CLASSIFICACOES_TEMA_CRIAR: '/temas/criar',
  CLASSIFICACOES_TIPO_DETAILS: '/tipos/:codigo/editar',
  CLASSIFICACOES_TIPO_CRIAR: '/tipos/criar',
  CLASSIFICACOES_SUBTIPO_DETAILS: '/subtipos/:codigo/editar',
  CLASSIFICACOES_SUBTIPO_CRIAR: '/subtipos/criar',

  PAINEIS_GERENCIAS: '/paineis',
  PAINEIS_CASA_CRIANCA_ADOLESCENTE: '/paineis/casa-crianca-adolescente',
  PAINEIS_CRDH: '/paineis/crdh',
  PAINEIS_FUNCI: '/paineis/funci',
  PAINEIS_CERLGBT: '/paineis/cerlgbt',

  INSTITUICOES_LIST: '/instituicoes',
  INSTITUICOES_CRIAR: '/instituicoes/criar',
  INSTITUICOES_DETAILS: '/instituicoes/:codigo',
  INSTITUICOES_EDITAR: '/instituicoes/:codigo/editar',

  CONSULTA_PUBLICA: '/consulta',
  CONSULTA_PUBLICA_RESULTADO: '/consulta/:canal/:protocolo/resultado',
  CADASTRO_DENUNCIA_PUBLICA: '/publico/denuncias/criar',

  POLITICA_PRIVACIDADE_OISOL_APP: '/politica-privacidade/oisol-app',


  ESTATISTICAS: 'https://dados.oisol.sps.ce.gov.br/public/dashboard/e3d3c506-c590-48a6-9929-e6bd70828084',
  ESTATISTICAS_PAINEIS: 'https://dados.oisol.sps.ce.gov.br/public/dashboard/9708da04-454a-46e7-8a8f-b7dc1cad6b99',
  ESTATISTICAS_PAINEIS_CRDH: 'https://dados.oisol.sps.ce.gov.br/public/dashboard/fbae2ad6-5682-434c-9fc0-5c28cc3ee0c9',
  ESTATISTICAS_CASA_DA_CRIANCA: 'https://dados.oisol.sps.ce.gov.br/public/dashboard/402b99ef-509f-4a1b-b599-9fdaba9d1370',
  ESTATISTICAS_CEMARIS: 'https://dados.oisol.sps.ce.gov.br/public/dashboard/892bbacc-c461-4ffe-a8e7-e7d4bdeb7479',
  ESTATISTICAS_PAINEIS_FUNCI: 'https://dados.oisol.sps.ce.gov.br/public/dashboard/54f3d7f2-e3d6-4a16-b82a-a20b7627befc',
  ESTATISTICAS_PAINEIS_CERLGBT: 'https://dados.oisol.sps.ce.gov.br/public/dashboard/b6436fd0-bf8f-4ca2-82b4-3bdee15a5200'
};

export const MANUAL_OISOL = 'MANUAL_OISOL.pdf';

export const PESSOA_SELECT_PADRAO = 'NÃO INFORMADO';

export const DATA_FORMATO = 'DD/MM/YYYY';
export const DATA_HORA_FORMATO = 'DD/MM/YYYY HH:mm';
export const CEP_MASK = '99999-999';
export const CPF_MASK = '999.999.999-99';
export const TELEFONE_MASK = ['(99) 9999-9999', '(99) 9 9999-9999'];