import api from "../api";
import qs from "qs";

export const pessoaService = {
  async list(params) {
    return api.get(`/pessoas`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  },

  async pesquisar(params) {
    return await api.get(`/pessoas`, {
      params,
    });
  },

  async buscar(id) {
    return await api.get(`/pessoas/${id}`);
  },

  async adicionar(payload) {
    return await api.post(`/pessoas`, payload);
  },

  async editar(id, payload) {
    return await api.put(`/pessoas/${id}`, payload);
  },

  async deletar(id) {
    return await api.delete(`/pessoas/${id}`);
  },
};
