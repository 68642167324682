import { Button, Space } from "antd";
import { PessoaPaginada } from "../Pessoas/components/PessoaPaginada";

export const PesquisaPessoaModal = ({ getPessoa }) => {
    
    return (
      <PessoaPaginada
        tableActions={(pessoa) => [
          <Space size="small">
            <Button
                type="primary"
                title={`inserir ${pessoa.nome}`}
                onClick={() => getPessoa(pessoa)}
            >
              inserir no atendimento
            </Button>
          </Space>
        ]}
        cardActions={(pessoa) => [
            <Space size="small">
              <Button
                  type="primary"
                  title={`inserir ${pessoa.nome}`}
                  onClick={() => getPessoa(pessoa)}
              >
                inserir no atendimento
              </Button>
            </Space>
          ]}
      />
    );
}