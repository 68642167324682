import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const BreadcrumbComponent = ({onCreate, onEdit, onView, descricaoRecurso, urlPesquisa, urlCadastro, urlEditar, urlHome, descricaoView }) => {
    return (
        <Breadcrumb id="breadcrumb">
            <Breadcrumb.Item key="home">
              <Link to={urlHome}>
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="registros">registros</Breadcrumb.Item>
            <Breadcrumb.Item key={`{descricaoRecurso}s`}>
              <Link to={urlPesquisa}>{descricaoRecurso}s</Link>
            </Breadcrumb.Item>

            {onCreate && (
              <Breadcrumb.Item key="criar">
                <strong>cadastrar {descricaoRecurso}</strong>
              </Breadcrumb.Item>
            )}

            {onView && (
              <Breadcrumb.Item key="{descricaoRecurso}">
                <strong>{descricaoView}</strong>
              </Breadcrumb.Item>
            )}

            {onEdit && (
              <>
                <Breadcrumb.Item key="{descricaoRecurso}">
                  <Link
                    to={urlEditar}
                  >
                    {descricaoView}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item key="editar">
                  <strong>editar {descricaoRecurso}</strong>
                </Breadcrumb.Item>
              </>
            )}
        </Breadcrumb>
    );
} 