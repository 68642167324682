import api from "../api";

export const tipoAtendimentoService = {
    async listarTiposAtendimento() {
        return await api.get('/tipos-atendimento');
    },

    async listarSubtiposAtendimento() {
        return await api.get('/subtipos-atendimento');
    },

    async listarSubtiposAtendimentoPorTipos(codigoTipoAtendimento) {
        return await api.get(`/tipos-atendimento/${codigoTipoAtendimento}/subtipos-atendimento`);
    },
};