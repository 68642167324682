import api from "../api";
import qs from "qs";

export const atendimentoService = {
    async listar(params) {
        return await api.get('/atendimentos', {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        });
    },

    async buscar(codigo) {
        return await api.get(`/atendimentos/${codigo}`);
    },

    async adicionar(atendimento) {
        return await api.post('/atendimentos', atendimento);
    },

    async atualizar(codigo, atendimento) {
        return await api.put(`/atendimentos/${codigo}`, atendimento);
    },

    async remover(codigo) {
        return await api.delete(`/atendimentos/${codigo}`);
    },
};