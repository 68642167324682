import {
  BankOutlined,
  ContainerOutlined,
  HomeOutlined,
  LineChartOutlined,
  LogoutOutlined,
  SwapOutlined,
  TeamOutlined,
  ToolOutlined,
  UnlockOutlined,
  UserSwitchOutlined,
  FileTextOutlined,
  SolutionOutlined,
} from "@ant-design/icons";

import FileDonwload from "js-file-download";

import { Menu as AntMenu, Button, Typography } from "antd";
import React, { useCallback, useContext, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { MANUAL_OISOL, urls, USER_ROLES } from "../../utils";
import ModalNewPass from "../AlterarSenha";
import { openNotification } from "../Notification";
import { storageService } from "../../services/resources/storageService";

function getMenuItem(label, key, icon, children, danger) {
  return {
    key,
    icon,
    children,
    label,
    danger,
  };
}

export const Menu = (props) => {
  const { currentMenu, setCurrentMenu, user, userLogout } =
    useContext(UserContext);
  const [openNovaSenha, setOpenNovaSenha] = React.useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    function getCurrentmenu() {
      if (pathname.includes("denuncias")) setCurrentMenu("denuncias");
      else if (pathname.includes("encaminhamentos"))
        setCurrentMenu("encaminhamentos");
      else if (pathname.includes("pessoas")) setCurrentMenu("pessoas");
      else if (pathname.includes("usuarios")) setCurrentMenu("usuarios:1");
      else if (pathname.includes("grupos")) setCurrentMenu("usuarios:2");
      else if (pathname.includes("instituicoes"))
        setCurrentMenu("instituicoes");
      else if (pathname.includes("classificacoes"))
        setCurrentMenu("gerenciamento:1");
      else if (pathname.includes("cadastros"))
        setCurrentMenu("gerenciamento:2");
      else setCurrentMenu("home");
    }

    getCurrentmenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleClick = (value) => {
    if (value.key === "sair") userLogout();
    else if (value.key === "senha") setOpenNovaSenha(true);
    else setCurrentMenu(value.key);
  };

  const handleCloseNovaSenha = useCallback(() => {
    setOpenNovaSenha(false);
  }, []);

  const handleDonwloadManual = async () => {
    try {
      const response = await storageService.getFileByName(MANUAL_OISOL);
      const { data: fileData } = response;

      FileDonwload(
        fileData,
        MANUAL_OISOL
      );
    } catch (err) {
      openNotification("error", <strong>Erro ao baixar manual do sistema!</strong>, err);
    }
  }

  return (
    <>
      <AntMenu
        onClick={handleClick}
        selectedKeys={[currentMenu]}
        subMenuCloseDelay="0.2"
        style={{
          marginTop: "2rem",
          backgroundColor: "#f2f2f2",
        }}
        {...props}
        items={[
          !user?.authorities?.includes(USER_ROLES.CONSULTAR_TODAS_DENUNCIAS) &&
            getMenuItem(
              <NavLink to="/">INÍCIO</NavLink>,
              "home",
              <HomeOutlined />
            ),

          (user?.authorities?.includes(USER_ROLES.CONSULTAR_ESTATISTICAS) ||
            user?.authorities?.includes(USER_ROLES.CONSULTAR_PAINEIS_CEMARIS) ||
            user?.authorities?.includes(
              USER_ROLES.CONSULTAR_PAINEIS_GERENCIAIS
            )) &&
            getMenuItem("INDICADORES", "indicadores:1", <LineChartOutlined />, [
              user?.authorities?.includes(USER_ROLES.CONSULTAR_ESTATISTICAS) &&
                getMenuItem(
                  <a target="_blank" rel="noreferrer" href={urls.ESTATISTICAS}>
                    INDICADORES E EVIDÊNCIAS
                  </a>,
                  "indicadores:1:1"
                ),

              user?.authorities?.includes(USER_ROLES.CONSULTAR_PAINEIS_GERENCIAIS) &&
                getMenuItem(
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={urls.ESTATISTICAS_PAINEIS}
                  >
                    INDICADORES GERENCIAIS
                  </a>,
                  "indicadores:1:2"
                ),

                getMenuItem("INDICADORES DA REDE DE PROTEÇÃO, GARANTIA E ASSISTÊNCIA", "indicadores:1:3", null,
                  [
                    user?.authorities?.includes(USER_ROLES.CONSULTAR_PAINEIS_CEMARIS) &&
                      getMenuItem(
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={urls.ESTATISTICAS_CEMARIS}
                        >
                          CEMARIS
                        </a>,
                        "cemaris"
                      ),

                    user?.authorities?.includes(USER_ROLES.CONSULTAR_PAINEIS_CASA_CRIANCA_ADOLSCENTE) &&
                      getMenuItem(
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={urls.ESTATISTICAS_CASA_DA_CRIANCA}
                        >
                          CASA DA CRIANÇA E DO ADOLESCENTE
                        </a>,
                        "casa-crianca"
                      ),

                    user?.authorities?.includes(USER_ROLES.CONSULTAR_PAINEL_CRDH) &&
                      getMenuItem(
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={urls.ESTATISTICAS_PAINEIS_CRDH}>
                            CRDH
                        </a>,
                        "painel-crdh"
                      ),

                    user?.authorities?.includes(USER_ROLES.CONSULTAR_PAINEL_FUNCI) &&
                      getMenuItem(
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={urls.ESTATISTICAS_PAINEIS_FUNCI}>
                            FUNCI - FORTALEZA
                        </a>,
                        "painel-funci"
                      ),

                    user?.authorities?.includes(USER_ROLES.CONSULTAR_PAINEL_CERLGBT) &&
                      getMenuItem(
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={urls.ESTATISTICAS_PAINEIS_CERLGBT}>
                            CERLGBT
                        </a>,
                        "painel-funci"
                      ),
                  ]
                ),
            ]),
            
            (user?.authorities?.includes(USER_ROLES.CONSULTAR_ATENDIMENTOS)) &&
            getMenuItem(
              <NavLink to={urls.PESQUISA_ATENDIMENTO}>ATENDIMENTO</NavLink>,
              "atendimentos",
              <SolutionOutlined />
            ),
          getMenuItem("DENÚNCIAS", "denuncias", <ContainerOutlined />, [
            user?.authorities?.includes(USER_ROLES.CONSULTAR_TODAS_DENUNCIAS) &&
              getMenuItem(
                <NavLink to={urls.DENUNCIAS_LIST}>POR STATUS</NavLink>,
                "denuncias:1"
              ),
            user?.authorities?.includes(USER_ROLES.CONSULTAR_TODAS_DENUNCIAS) &&
              getMenuItem(
                <NavLink to={urls.DENUNCIAS_GERAL_LIST}>EM GERAL</NavLink>,
                "denuncias:2"
              ),
            !user?.authorities?.includes(
              USER_ROLES.CONSULTAR_TODAS_DENUNCIAS
            ) &&
              getMenuItem(
                <NavLink to={urls.DENUNCIAS_LIST}>DA INSTITUIÇÃO</NavLink>,
                "denuncias:1"
              ),
            !user?.authorities?.includes(
              USER_ROLES.CONSULTAR_TODAS_DENUNCIAS
            ) &&
              getMenuItem(
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={urls.DENUNCIAS_PUBLICA}>
                    EM GERAL
                  </a>,
                "denuncias:2"
              ),
            user?.authorities?.includes(
              USER_ROLES.CONSULTAR_TODAS_DENUNCIAS_MUNICIPIO
            ) &&
              getMenuItem(
                <NavLink to={urls.DENUNCIAS_MUNICIPIO}>NO MUNICÍPIO</NavLink>,
                "denuncias:3"
              ),
          ]),
          getMenuItem("ENCAMINHAMENTOS", "encaminhamentos", <SwapOutlined />, [
            user?.authorities?.includes(
              USER_ROLES.CONSULTAR_ENCAMINHAMENTOS_INSTITUICAO
            ) &&
              getMenuItem(
                <NavLink to={urls.ENCAMINHAMENTOS_RECEBIDOS}>
                  RECEBIDOS
                </NavLink>,
                "encaminhamentos:1"
              ),
            user?.authorities?.includes(
              USER_ROLES.CONSULTAR_ENCAMINHAMENTOS_INSTITUICAO
            ) &&
              getMenuItem(
                <NavLink to={urls.ENCAMINHAMENTOS_ENVIADOS}>ENVIADOS</NavLink>,
                "encaminhamentos:2"
              ),
          ]),
          (user?.authorities?.includes(USER_ROLES.CONSULTAR_PESSOAS)  || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA)) &&
            getMenuItem(
              <NavLink to={urls.PESSOAS_LIST}>PESSOAS</NavLink>,
              "pessoas",
              <TeamOutlined />
            ),
          (user?.authorities?.includes(
            USER_ROLES.CONSULTAR_TODAS_INSTITUICOES
          ) ||
            user?.authorities?.includes(USER_ROLES.GERENCIAR_INSTITUICOES)) &&
            getMenuItem(
              <NavLink to={urls.INSTITUICOES_LIST}>INSTITUIÇÕES</NavLink>,
              "instituicoes",
              <BankOutlined />
            ),
          user?.authorities?.includes(USER_ROLES.GERENCIAR_USUARIOS) &&
            getMenuItem("ACESSOS", "usuarios", <UserSwitchOutlined />, [
              getMenuItem(
                <NavLink to={urls.USUARIOS_LIST}>USUÁRIOS</NavLink>,
                "usuarios:1"
              ),
              getMenuItem(
                <NavLink to={urls.GRUPOS_LIST}>GRUPOS</NavLink>,
                "usuarios:2"
              ),
            ]),
          user?.authorities?.includes(USER_ROLES.GERENCIAR_USUARIOS) &&
            getMenuItem("GERENCIAMENTO", "gerenciamento", <ToolOutlined />, [
              getMenuItem("CLASSIFICAÇÕES", "gerenciamento:1", null, [
                getMenuItem(
                  <NavLink to={`${urls.CLASSIFICACOES}?mode=temas`}>
                    TEMAS
                  </NavLink>,
                  "gerenciamento:1:1"
                ),
                getMenuItem(
                  <NavLink to={`${urls.CLASSIFICACOES}?mode=tipos`}>
                    TIPOS
                  </NavLink>,
                  "gerenciamento:1:2"
                ),
                getMenuItem(
                  <NavLink to={`${urls.CLASSIFICACOES}?mode=subtipos`}>
                    SUBTIPOS
                  </NavLink>,
                  "gerenciamento:1:3"
                ),
              ]),
              getMenuItem("CADASTROS BÁSICOS", "gerenciamento:2", null, [
                getMenuItem(
                  <NavLink to={`${urls.CADASTROS_BASICOS}?mode=agravantes`}>
                    AGRAVANTES
                  </NavLink>,
                  "gerenciamento:2:1"
                ),
                getMenuItem(
                  <NavLink to={`${urls.CADASTROS_BASICOS}?mode=bairros`}>
                    BAIRROS
                  </NavLink>,
                  "gerenciamento:2:2"
                ),
                getMenuItem(
                  <NavLink to={`${urls.CADASTROS_BASICOS}?mode=canais`}>
                    CANAIS
                  </NavLink>,
                  "gerenciamento:2:3"
                ),
                getMenuItem(
                  <NavLink
                    to={`${urls.CADASTROS_BASICOS}?mode=classificacaoAtributos`}
                  >
                    CLASSIFICAÇÃO PROCEDÊNCIA
                  </NavLink>,
                  "gerenciamento:2:30"
                ),
                getMenuItem(
                  <NavLink to={`${urls.CADASTROS_BASICOS}?mode=deficiencias`}>
                    DEFICIÊNCIAS
                  </NavLink>,
                  "gerenciamento:2:4"
                ),
                getMenuItem(
                  <NavLink to={`${urls.CADASTROS_BASICOS}?mode=doencas`}>
                    DOENÇAS
                  </NavLink>,
                  "gerenciamento:2:5"
                ),
                getMenuItem(
                  <NavLink
                    to={`${urls.CADASTROS_BASICOS}?mode=localOcorrencia`}
                  >
                    LOCAL DE NOTIFICAÇÃO/ MANIFESTAÇÃO
                  </NavLink>,
                  "gerenciamento:2:6"
                ),
                getMenuItem(
                  <NavLink to={`${urls.CADASTROS_BASICOS}?mode=genero`}>
                    SEXO
                  </NavLink>,
                  "gerenciamento:2:7"
                ),
                getMenuItem(
                  <NavLink
                    to={`${urls.CADASTROS_BASICOS}?mode=identidadeGenero`}
                  >
                    IDENTIDADE DE GÊNERO
                  </NavLink>,
                  "gerenciamento:2:8"
                ),
                getMenuItem(
                  <NavLink to={`${urls.CADASTROS_BASICOS}?mode=sexualidade`}>
                    ORIENTAÇÃO SEXUAL
                  </NavLink>,
                  "gerenciamento:2:9"
                ),
                getMenuItem(
                  <NavLink to={`${urls.CADASTROS_BASICOS}?mode=renda`}>
                    RENDA
                  </NavLink>,
                  "gerenciamento:2:10"
                ),
                getMenuItem(
                  <NavLink to={`${urls.CADASTROS_BASICOS}?mode=religiao`}>
                    RELIGIÃO
                  </NavLink>,
                  "gerenciamento:2:11"
                ),
                getMenuItem(
                  <NavLink to={`${urls.CADASTROS_BASICOS}?mode=frequencia`}>
                    FREQUÊNCA DA NOTIFICAÇÃO/ MANIFESTAÇÃO
                  </NavLink>,
                  "gerenciamento:2:12"
                ),
                getMenuItem(
                  <NavLink to={`${urls.CADASTROS_BASICOS}?mode=ocupacao`}>
                    OCUPAÇÕES
                  </NavLink>,
                  "gerenciamento:2:13"
                ),
                getMenuItem(
                  <NavLink to={`${urls.CADASTROS_BASICOS}?mode=grauInstrucao`}>
                    GRAU DE INSTITUIÇÃO
                  </NavLink>,
                  "gerenciamento:2:14"
                ),
                process.env.REACT_APP_ENV !== "production" &&
                  getMenuItem(
                    <NavLink to={`${urls.CADASTROS_BASICOS}?mode=paineis`}>
                      ESTATISTICAS
                    </NavLink>,
                    "gerenciamento:2:15"
                  ),
              ]),
            ]),
          getMenuItem(
            <Button
                style={{ textAlign: "start", fontSize: "14px", margin: "0", padding: "0" }}
                block
                type="text"
                onClick={() => {
                  handleDonwloadManual();
                }}
                icon={<FileTextOutlined />}
              >
                MANUAL
            </Button>  
          ),
          getMenuItem("ALTERAR SENHA", "senha", <UnlockOutlined />),
          getMenuItem(
            <Typography.Text strong style={{ color: "#fa4545" }}>
              SAIR
            </Typography.Text>,
            "sair",
            <LogoutOutlined style={{ color: "#fa4545" }} />,
            null,
            true
          ),
        ]}
      />
      <ModalNewPass onClose={handleCloseNovaSenha} visible={openNovaSenha} />
    </>
  );
};
