import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";

import DialogModal from "../../../components/DialogModal";
import { openNotification } from "../../../components/Notification";
import { ArquivarDenunciaModal } from "../components/ArquivarDenunciaModal";
import fetchImage from "./components/DocumentPDF/image";
import LOGO from "./components/DocumentPDF/LOGO-HEADER.png";
import footer from "./components/DocumentPDF/ondas.png";

import FileDonwload from "js-file-download";

import {
  Badge,
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Dropdown,
  List,
  Menu,
  Row,
  Skeleton,
  Tabs,
} from "antd";

import {
  CaretRightOutlined,
  CheckSquareOutlined,
  DownloadOutlined,
  EditOutlined,
  FileDoneOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  InboxOutlined,
  LaptopOutlined,
  MobileOutlined,
  MoreOutlined,
  RollbackOutlined,
} from "@ant-design/icons";

import { useWidth } from "../../../hooks";
import { STATUS, STATUS_COLORS, urls, USER_ROLES } from "../../../utils";

import PageHeader from "../../../components/PageHeader";
import { denuncianteService } from "../../../services/resources/denuncianteService";
import {
  Anexos,
  Classificacao,
  DenunciasAssociadas,
  Encaminhamentos,
  Envolvidos,
  Ocorrencia,
} from "./components";
import { denunciaService } from "../../../services/resources/denunciaService";
import { MapView } from "../../../components/MapView";

const DATA_FORMATO = "DD/MM/YYYY";
const ORIGEM_DENUNCIA = "OISOL";

const CreateDenunciation = ({ onView, onEdit, onCreate }) => {
  const { user } = React.useContext(UserContext);
  const history = useHistory();
  const { codigo } = useParams();

  const [action, setAction] = useState("occurrence");
  const [paramsForm, setParamsForm] = useState({});
  const [OpenModalFileReport, setOpenModalFileReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialogModal, setOpenDialogModal] = useState(false);
  const [openDialogModalConfirmarAnalise, setOpenDialogModalConfirmarAnalise] =
    useState(false);
  const [denunciaInstituicao, setDenunciaInstituicao] = useState(false);
  const [imageFooter, setImageFooter] = useState("");
  const [imageLOGO, setImageLOGO] = useState("");
  const [enderecoDenuncia, setEnderecoDenuncia] = useState("");
  const [paramsPDF, setParamsPDF] = useState({});
  const { width } = useWidth();

  const handleOpenModalFileReport = useCallback(() => {
    setOpenModalFileReport(true);
  }, []);
  const handleCloseModalFileReport = useCallback(() => {
    setOpenModalFileReport(false);
  }, []);
  const handleOpenDialogModal = useCallback(() => {
    setOpenDialogModal(true);
  }, []);
  const handleCloseDialogModal = useCallback(() => {
    setOpenDialogModal(false);
  }, []);

  useEffect(() => {
    if (!onCreate) {
      getDenuncia(codigo);
      handleImage();
    }
    // eslint-disable-next-line
  }, [codigo]);

  async function getDenuncia() {
    try {
      setLoading(true);
      const { data } = await denuncianteService.getDenunciation(codigo);

      const {
        dataCadastro,
        dataLimite,
        dataOcorrencia,
        status,
        descricao,
        localOcorrencia,
        frequencia,
        endereco,
        canal,
        protocolo,
        identificadorExterno,
        criticidade,
        sigilo,
        observacao,
        vitimas,
        suspeitos,
        classificacoes,
        denunciaAssociadas,
        agravantes,
        usuario,
        justificativa,
        instituicao,
        origemDenunciaDescricao,
      } = data;

      setEnderecoDenuncia(endereco);
      
      if (
        (usuario?.instituicao?.codigo !== user.codigo_instituicao &&
          !user.authorities.includes(USER_ROLES.ALTERAR_TODAS_DENUNCIAS)) ||
        (status.descricao !== "A ANALISAR" &&
          usuario?.instituicao?.codigo === user.codigo_instituicao &&
          !user.authorities.includes(USER_ROLES.ALTERAR_DENUNCIAS_INSTITUICAO))
      ) {
        setDenunciaInstituicao(true);
        history.push(urls.DENUNCIA_DETAILS.replace(":codigo", codigo));
      }

      let agravanteSelect = [];

      for (let index = 0; index < agravantes.length; index++) {
        agravanteSelect.push(agravantes[index].codigo);
      }

      setParamsForm({
        codigo: codigo,
        dataCadastro: dataCadastro,
        dataLimite: dataLimite,
        status: status?.descricao,
        dataOcorrencia: dataOcorrencia,
        descricao: descricao,
        localOcorrenciaSelect: localOcorrencia?.codigo,
        frequenciaOcorrenciaSelect: frequencia?.codigo,
        logradouro: endereco?.logradouro,
        cep: endereco?.cep,
        estadoSelect: endereco?.bairro?.cidade?.estado?.id,
        cidadeSelect: endereco?.bairro?.cidade?.id,
        bairroSelect: endereco?.bairro?.id,
        numero: endereco?.numero,
        complemento: endereco?.complemento,
        canalSelect: canal?.codigo,
        identificador: protocolo,
        protocoloExterno: identificadorExterno,
        criticidadeOcorrenciaSelect: criticidade?.codigo,
        sigiloOcorrenciaSelect: sigilo,
        origemDenunciaDescricao,
        observacao,
        agravanteSelect,
        usuario,
        justificativa,
        instituicao,
      });

      setParamsPDF({
        codigo: codigo,
        dataCadastro: moment(dataCadastro).format(DATA_FORMATO),
        dataLimite: moment(dataLimite).format(DATA_FORMATO),
        dataOcorrencia: moment(dataOcorrencia).format(DATA_FORMATO),
        descricao: descricao,
        localOcorrenciaSelect: localOcorrencia?.descricao,
        frequenciaOcorrenciaSelect: frequencia?.descricao,
        logradouro: `${endereco?.logradouro}, Nº ${endereco?.numero}`,
        estadoSelect: endereco?.bairro?.cidade?.estado?.nome,
        cidadeSelect: endereco?.bairro?.cidade?.nome,
        bairroSelect: endereco?.bairro?.nome,
        complemento: endereco?.complemento,
        protocolo: protocolo,
        protocoloExterno: identificadorExterno,
        criticidadeOcorrenciaSelect: criticidade?.descricao,
        sigiloOcorrenciaSelect: sigilo ? "SIM" : "NÃO",
        observacao: observacao,
        classificacoes: classificacoes,
        canal: canal,
        vitimas,
        suspeitos,
        denunciaAssociadas,
        agravantes,
        justificativa,
      });
    } catch (error) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os dados da denúncia!</strong>,
        error?.response?.data?.detail
      );
      history.push(urls.DENUNCIAS_LIST);
    } finally {
      setLoading(false);
    }
  }

  async function handleImage() {
    const auxFooter = await fetchImage(footer);
    setImageFooter(auxFooter);
    const auxLOGO = await fetchImage(LOGO);
    setImageLOGO(auxLOGO);
  }

  const handleDonwloadDenuncia = async (codigoDenuncia) => {
    try {
      const response = await denunciaService.denunciante.imprimir(
        codigoDenuncia
      );
      const { data: fileData } = response;

      FileDonwload(
        fileData,
        "OISOL_" +
          paramsPDF?.canal?.sigla +
          "_" +
          paramsForm?.protocoloExterno +
          ".pdf"
      );
    } catch (err) {
      openNotification("error", <strong>Erro ao baixar denúncia!</strong>, err);
      console.error(err);
    }
  };

  async function confirmarAnalise() {
    await denuncianteService
      .abrir(codigo)
      .then(() => {
        openNotification(
          "success",
          <strong>Análise confirmada!</strong>,
          "Denúncia agora se encontra em andamento."
        );
        history.push(urls.DENUNCIAS_LIST);
      })
      .catch(({ response }) =>
        openNotification(
          "error",
          <strong>
            Ocorreu um erro ao confirmar a análise da dessa denúncia!
          </strong>,
          response.data.detail
        )
      );
  }

  const handleAction = useCallback(
    (value) => {
      setAction(value);
    },
    [setAction]
  );

  async function handleFinalize() {
    await denuncianteService
      .finalizeDenunciation(codigo)
      .then(() => {
        openNotification(
          "success",
          <strong>{`Denúncia finalizada com sucesso!`}</strong>,
          "Denúncia agora se encontra finalizada."
        );
        history.push(urls.DENUNCIAS_LIST);
      })
      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao finalizar a denúncia!</strong>,
          reason.response.data.userMessage
        );
      });
  }

  async function desarquivarDenuncia(codigoDenuncia) {
    try {
      await denuncianteService.desarquivarDenuncia(codigoDenuncia);
      openNotification(
        "success",
        <strong>Denúncia desarquivada com sucesso!</strong>
      );
      getDenuncia();
    } catch (error) {
      console.log(error);
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os tipos da denúncia!</strong>,
        error?.response?.data?.detail
      );
    } finally {
    }
  }

  const menuActions = (
    <Menu>
      {onView &&
        !denunciaInstituicao &&
        paramsForm?.status !== STATUS.ARQUIVADA && (
          <Menu.Item key="editar">
            <Button
              style={{ textAlign: "start", fontSize: "1rem" }}
              block
              type="text"
              icon={<EditOutlined />}
              onClick={() =>
                history.push(urls.DENUNCIA_EDITAR.replace(":codigo", codigo))
              }
            >
              editar denúncia
            </Button>
          </Menu.Item>
        )}
      <Menu.Item key="baixar">
        <Button
          style={{ textAlign: "start", fontSize: "1rem" }}
          block
          type="text"
          onClick={() => {
            handleDonwloadDenuncia(codigo);
          }}
          icon={<DownloadOutlined />}
        >
          baixar denúncia
        </Button>
      </Menu.Item>
      {user?.authorities?.includes(USER_ROLES.ARQUIVAR_DENUNCIA) &&
        paramsForm.status === STATUS.ANALISAR && (
          <Menu.Item key="arquivar">
            <Button
              style={{ textAlign: "start", fontSize: "1rem" }}
              block
              type="text"
              onClick={handleOpenModalFileReport}
              icon={<InboxOutlined />}
              danger
            >
              arquivar denúncia
            </Button>
          </Menu.Item>
        )}

      {user?.authorities?.includes(USER_ROLES.ARQUIVAR_DENUNCIA) &&
        paramsForm.status === STATUS.ARQUIVADA && (
          <Menu.Item key="desarquivar">
            <Button
              style={{ textAlign: "start", fontSize: "1rem" }}
              block
              type="text"
              onClick={() => desarquivarDenuncia(codigo)}
              icon={<FolderOpenOutlined />}
            >
              desarquivar denúncia
            </Button>
          </Menu.Item>
        )}

      {(user?.authorities?.includes(USER_ROLES.ALTERAR_TODAS_DENUNCIAS) ||
        user?.authorities?.includes(
          USER_ROLES.ALTERAR_DENUNCIAS_INSTITUICAO
        )) && (
        <>
          {[STATUS.ANALISAR].includes(paramsForm?.status) && (
            <Menu.Item key="analisar">
              <Button
                style={{ textAlign: "start", fontSize: "1rem" }}
                block
                type="text"
                icon={<CheckSquareOutlined />}
                onClick={() => setOpenDialogModalConfirmarAnalise(true)}
              >
                confirmar análise
              </Button>
            </Menu.Item>
          )}
          {["A VALIDAR"].includes(paramsForm?.status) && (
            <Menu.Item key="finalizar">
              {" "}
              <Button
                style={{ textAlign: "start", fontSize: "1rem" }}
                block
                type="text"
                onClick={handleOpenDialogModal}
                icon={<FileDoneOutlined />}
              >
                finalizar
              </Button>
            </Menu.Item>
          )}
        </>
      )}
      <Menu.Item key="voltar">
        <Button
          style={{ textAlign: "start", fontSize: "1rem" }}
          block
          type="text"
          danger
          onClick={() => history.push("/")}
          icon={<RollbackOutlined />}
        >
          sair da denúncia
        </Button>
      </Menu.Item>
    </Menu>
  );

  const renderExtraHeader = () => (
    <Dropdown
      trigger={["hover", "click"]}
      placement="bottomRight"
      overlay={menuActions}
    >
      {width < 900 ? (
        <Button
          style={{
            textAlign: "start",
            cursor: "pointer",
          }}
          block
          type="text"
          icon={<MoreOutlined style={{ fontSize: "1.2rem" }} />}
        />
      ) : (
        <Button
          style={{
            textAlign: "start",
            fontSize: "1rem",
            cursor: "pointer",
          }}
          block
          type="text"
        >
          AÇÕES <MoreOutlined style={{ fontSize: "1.2rem" }} />
        </Button>
      )}
    </Dropdown>
  );

  return !loading ? (
    <div id="sectionMain">
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="registros">registros</Breadcrumb.Item>
        <Breadcrumb.Item key="usuarios">
          <Link to={urls.DENUNCIAS_LIST}>denúncias</Link>
        </Breadcrumb.Item>

        {onCreate && (
          <Breadcrumb.Item key="criar">
            <strong>cadastrar denúncia</strong>
          </Breadcrumb.Item>
        )}

        {onView && (
          <Breadcrumb.Item key="usuario">
            <strong>denúncia #{paramsForm?.protocoloExterno}</strong>
          </Breadcrumb.Item>
        )}

        {onEdit && (
          <>
            <Breadcrumb.Item key="usuario">
              <Link to={urls.DENUNCIA_DETAILS.replace(":codigo", codigo)}>
                denúncia #{paramsForm?.protocoloExterno}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="editar">
              <strong>editar denúncia</strong>
            </Breadcrumb.Item>
          </>
        )}
      </Breadcrumb>

      <div className="section-children">
        <div className="container-section">
          <PageHeader
            titulo={
              onCreate ? (
                "CADASTRAR DENÚNCIA"
              ) : (
                <Col
                  style={{
                    display: "flex",
                    gap: ".5rem",
                    flexDirection: "column",
                  }}
                >
                  <Col
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <h2>
                      {onEdit && !denunciaInstituicao && "EDITAR "}DENÚNCIA #
                      {paramsForm?.protocoloExterno}{" "}
                    </h2>
                    <Badge
                      style={{
                        backgroundColor: STATUS_COLORS[paramsForm?.status],
                        color: "#333333",
                        fontWeight: "bold",
                      }}
                      count={
                        paramsForm?.status === "A ANALISAR"
                          ? "A ANALISAR"
                          : paramsForm?.status
                      }
                    />
                    {paramsForm?.origemDenunciaDescricao === "APP" ? (
                      <MobileOutlined style={{ fontSize: 18 }} />
                    ) : paramsForm?.origemDenunciaDescricao === "SITE" ? (
                      <LaptopOutlined style={{ fontSize: 18 }} />
                    ) : null}
                  </Col>

                  <small>
                    Cadastrada em{" "}
                    {`${moment(paramsForm?.dataCadastro).format(
                      "DD/MM/YYYY"
                    )} às ${moment(paramsForm?.dataCadastro).format("HH:mm")}`}
                    {paramsForm?.dataLimite &&
                      `, com prazo para ${moment(paramsForm?.dataLimite).format(
                        "DD/MM/YYYY"
                      )}`}
                    <br />
                    {(user?.authorities?.includes(USER_ROLES.VISUALIZAR_OPERADOR_DENUNCIA)) && (`Criada por ${paramsForm?.usuario?.nome} em`)} {paramsForm?.instituicao?.nome}
                  </small>
                </Col>
              )
            }
            subtitulo={onCreate && "Inserir informações sobre a denúncia"}
            extra={!onCreate && renderExtraHeader()}
          />

          {paramsForm?.justificativa &&
            paramsForm?.status === STATUS.ARQUIVADA && (
              <Row>
                <Col span={24}>
                  <List
                    header={"Justificativas de arquivamento"}
                    itemLayout="horizontal"
                    dataSource={paramsForm?.justificativa?.split(";")}
                    renderItem={(item, i) => (
                      <List.Item>
                        <List.Item.Meta avatar={i + 1} description={item} />
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            )}
          {!onCreate ? (
            width >= 720 ? (
              <Row>
                <Col span={24}>
                  <Tabs activeKey={action} onChange={handleAction}>
                    <Tabs.TabPane
                      tab={<strong>Ocorrência</strong>}
                      key="occurrence"
                    >
                      <Ocorrencia
                        handleNext={handleAction}
                        getDenuncia={getDenuncia}
                        onView={onView}
                        onEdit={onEdit}
                        onCreate={onCreate}
                      />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                      tab={<strong>Denunciante(s)</strong>}
                      key="denunciator"
                    >
                      <Envolvidos
                        tipo="denunciante"
                        handleNext={handleAction}
                        enable={onEdit}
                        next="victim"
                        prev="occurrence"
                      />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={<strong>Vítima(s)</strong>} key="victim">
                      <Envolvidos
                        tipo="vitima"
                        handleNext={handleAction}
                        enable={onEdit}
                        next="suspect"
                        prev="denunciator"
                      />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                      tab={<strong>Possível(s) suspeitos(s)</strong>}
                      key="suspect"
                    >
                      <Envolvidos
                        tipo="suspeito"
                        handleNext={handleAction}
                        enable={onEdit}
                        next="classification"
                        prev="victim"
                      />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                      tab={<strong>Classificação</strong>}
                      key="classification"
                    >
                      <Classificacao
                        handleNext={handleAction}
                        onView={onView}
                        onEdit={onEdit}
                        onCreate={onCreate}
                        codigo={codigo}
                      />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                      tab={<strong>Anexos da denúncia</strong>}
                      key="anexos"
                    >
                      <Anexos
                        handleNext={handleAction}
                        onView={onView}
                        onEdit={onEdit}
                        onCreate={onCreate}
                        codigo={codigo}
                      />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                      tab={<strong>Denúncia(s) associada(s)</strong>}
                      key="associated"
                    >
                      <DenunciasAssociadas
                        handleNext={handleAction}
                        onView={onView}
                        onEdit={onEdit}
                        onCreate={onCreate}
                      />
                    </Tabs.TabPane>
                    {![STATUS.ANALISAR].includes(paramsForm?.status) && (
                      <Tabs.TabPane
                        tab={<strong>Encaminhamento(s)</strong>}
                        key="forwarding"
                      >
                        <Encaminhamentos onView={onView} />
                      </Tabs.TabPane>
                    )}

                    <Tabs.TabPane
                      tab={<strong>Localização</strong>}
                      key="location"
                    >
                      <MapView enderecoDenuncia={enderecoDenuncia} />
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={24}>
                  <Collapse
                    className="collapse-personal"
                    accordion
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    expandIconPosition="right"
                    ghost
                    activeKey={action}
                    onChange={handleAction}
                    destroyInactivePanel
                  >
                    <Collapse.Panel
                      header={<strong>Ocorrência</strong>}
                      key="occurrence"
                      className={"linear-grey"}
                    >
                      <Ocorrencia
                        handleNext={handleAction}
                        getDenuncia={getDenuncia}
                        onView={onView}
                        onEdit={onEdit}
                        onCreate={onCreate}
                      />
                    </Collapse.Panel>

                    <Collapse.Panel
                      header={<strong>Denunciante(s)</strong>}
                      key="denunciator"
                      className={"linear-grey"}
                    >
                      <Envolvidos
                        tipo="denunciante"
                        handleNext={handleAction}
                        enable={onEdit}
                        showActions={false}
                      />
                    </Collapse.Panel>

                    <Collapse.Panel
                      header={<strong>Vítima(s)</strong>}
                      key="victim"
                      className={"linear-grey"}
                    >
                      <Envolvidos
                        tipo="vitima"
                        handleNext={handleAction}
                        enable={onEdit}
                        showActions={false}
                      />
                    </Collapse.Panel>

                    <Collapse.Panel
                      header={<strong>Possível(s) suspeitos(s)</strong>}
                      key="suspect"
                      className={"linear-grey"}
                    >
                      <Envolvidos
                        tipo="suspeito"
                        handleNext={handleAction}
                        enable={onEdit}
                        showActions={false}
                      />
                    </Collapse.Panel>

                    <Collapse.Panel
                      header={<strong>Classificação</strong>}
                      key="classification"
                      className={"linear-grey"}
                    >
                      <Classificacao
                        handleNext={handleAction}
                        onView={onView}
                        onEdit={onEdit}
                        onCreate={onCreate}
                        codigo={codigo}
                        showActions={false}
                      />
                    </Collapse.Panel>

                    <Collapse.Panel
                      header={<strong>Anexos da denúncia</strong>}
                      key="anexos"
                      className={"linear-grey"}
                    >
                      <Anexos
                        handleNext={handleAction}
                        onView={onView}
                        onEdit={onEdit}
                        onCreate={onCreate}
                        codigo={codigo}
                        showActions={false}
                      />
                    </Collapse.Panel>

                    <Collapse.Panel
                      header={<strong>Denúncia(s) associada(s)</strong>}
                      key="associated"
                      className={"linear-grey"}
                    >
                      <DenunciasAssociadas
                        handleNext={handleAction}
                        onView={onView}
                        onEdit={onEdit}
                        onCreate={onCreate}
                        showActions={false}
                      />
                    </Collapse.Panel>
                    {![STATUS.ANALISAR].includes(paramsForm?.status) && (
                      <Collapse.Panel
                        header={<strong>Encaminhamentos(s)</strong>}
                        key="forwarding"
                        className={"linear-grey"}
                      >
                        <Encaminhamentos onView={onView} showActions={false} />
                      </Collapse.Panel>
                    )}

                    <Collapse.Panel
                      header={<strong>Localização</strong>}
                      key="location"
                      className={"linear-grey"}
                    >
                      <MapView enderecoDenuncia={enderecoDenuncia} />
                    </Collapse.Panel>
                  </Collapse>
                </Col>
              </Row>
            )
          ) : (
            <Ocorrencia
              handleNext={handleAction}
              getDenuncia={getDenuncia}
              onView={onView}
              onEdit={onEdit}
              onCreate={onCreate}
            />
          )}
        </div>
      </div>

      {OpenModalFileReport && (
        <ArquivarDenunciaModal
          onClose={handleCloseModalFileReport}
          codigo={codigo}
          protocolo={paramsForm?.identificador}
          onFinish={() => getDenuncia()}
        />
      )}
      {openDialogModal && (
        <DialogModal
          title="ATENÇÃO! FINALIZAR DENÚNCIA"
          Subtitle="Deseja mesmo finalizar essa denúncia?"
          type="fileReport"
          handle_Function={handleFinalize}
          onClose={handleCloseDialogModal}
        />
      )}

      {openDialogModalConfirmarAnalise && (
        <DialogModal
          onClose={() => setOpenDialogModalConfirmarAnalise(false)}
          title={<span>Você tem certeza que deseja confirmar a análise?</span>}
          Subtitle="Ao confirmar a análise, está denúncia irá para EM ABERTO"
          handle_Function={() => {
            confirmarAnalise();
            setOpenDialogModalConfirmarAnalise(false);
          }}
        />
      )}
    </div>
  ) : (
    <>
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </>
  );
};

export default CreateDenunciation;
