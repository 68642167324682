import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Card, Col, Empty, Pagination, Row, Skeleton, Space, Table } from "antd";
import Meta from "antd/lib/card/Meta";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { OPCOES_TAMANHO_PAGINA, STATUS_COLORS, removeEmpty, urls } from "../../../utils";
import { FiltroComponent } from "../Filtro";
import { atendimentoService } from "../../../services/resources/atendimentoService";
import { openNotification } from "../../../components/Notification";
import moment from "moment";


export const TabelaAtendimento = () => {

    const history = useHistory();

    const [isTableView, setIsTableView] = useState(true);
    const [loading, setLoading] = useState(false);

    const [atendimentos, setAtendimentos] = useState([]);
    
    const [filter, setFilter] = useState({
        ativo: true,
        page: 0,
        size: OPCOES_TAMANHO_PAGINA[0],
    });

    const [paginacao, setPaginacao] = useState({
        currentPage: 0,
        totalElements: 0,
        size: OPCOES_TAMANHO_PAGINA[0],
    });

    useEffect(() => {
        getAtendimentos(filter);

        // eslint-disable-next-line
    }, [filter]);

    async function getAtendimentos() {
        try {
            setLoading(true);
            const response = await atendimentoService.listar(removeEmpty(filter));
            setAtendimentos(response?.data?.content);
            setPaginacao({
                currentPage: response?.data?.currentPage,
                totalElements: response?.data?.totalElements,
                size: response?.data?.size,
            });
        } catch (error) {
            openNotification(
                "error",
                <strong>Ocorreu um erro ao buscar os atendimentos!</strong>,
                `${error?.response?.data?.detail}`
              );
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
          title: <strong>status</strong>,
          dataIndex: ["status", "descricao"],
          key: "status.descricao",
          sorter: {
            multiple: 1,
          },
          render: (status) => (
            <Badge
              style={{
                backgroundColor: STATUS_COLORS[status],
                color: "#333333",
                fontWeight: "bold",
              }}
              count={status}
            />
          ),
        },
        {
            title: <strong>data atendimento</strong>,
            dataIndex: "dataAtendimento",
            key: "dataAtendimento",
            sorter: {
              multiple: 1,
            },
            responsive: ["md"],
            render: (init) => (init ? moment(init).format("DD/MM/yyyy HH:mm") : ""),
        },
        {
          title: <strong>protocolo</strong>,
          dataIndex: "protocolo",
          key: "protocolo",
          sorter: {
            multiple: 1,
          },
        },
        {
          title: <strong>canal atendimento</strong>,
          dataIndex: "canalAtendimentoNome",
          key: "canalAtendimentoNome",
          sorter: {
            multiple: 1,
          },
        },
        {
          title: <strong>tipo atendimento</strong>,
          dataIndex: "subtipoAtendimentoDescricao",
          key: "subtipoAtendimentoDescricao",
          sorter: {
            multiple: 1,
          },
        },
        {
          title: <strong>ações</strong>,
          key: "action",
          fixed: "right",
          width: 128,
          render: ({ codigo }) => (
            <Space size="small">
                <Button
                    type="primary"
                    ghost
                    className="action-button"
                    title={`Visualizar atendimento`}
                    onClick={() => history.push(urls.VISUALIZAR_ATENDIMENTO.replace(":codigo", codigo))}
                >
                    <EyeOutlined style={{ fontSize: "1.2rem" }} />
                </Button>
                 
                <Button
                    type="primary"
                    className="action-button"
                    title={`Editar atendimento`}
                    onClick={() => history.push(urls.EDITAR_ATENDIMENTO.replace(":codigo", codigo))}
                    >
                    <EditOutlined style={{ fontSize: "1.2rem" }} />
                </Button>
                 
                <Button
                    className="action-button"
                    type="danger"
                    title={`Excluir atendimento`}
                    >
                    <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                </Button>
            </Space>
          ),
        },
      ];

    return (
        <>
        {isTableView ? (
            <Row>
                <Col span={24}>
                    <Table
                        loading={loading}
                        dataSource={atendimentos}
                        columns={columns}
                        size="small"
                        rowKey={({ codigo }) => codigo}
                        totalSize={paginacao.totalElements}
                        locale={{
                        emptyText: <Empty description={<h2>Sem atendimentos</h2>} />,
                        }}
                        pagination={false}
                    />
                </Col>
            </Row>
        ) : (
            <Row gutter={16} justify="space-between">
                {atendimentos.map((atendimento) => (
                    <Col key={atendimento.codigo} xs={24} md={12} lg={8} xl={6}>
                        <Card
                            style={{ width: "100%", marginTop: 16 }}
                            actions={[
                                <Button
                                    key="view"
                                    icon={
                                    <EyeOutlined style={{ fontSize: "1.2rem" }}/>
                                    }
                                />,
                                <Button
                                    key="edit"
                                    type="primary"
                                    icon={
                                    <EditOutlined style={{ fontSize: "1.2rem" }}/>
                                    }
                                />,
                                <Button
                                    key="delete"
                                    danger
                                    icon={<DeleteOutlined/>}
                                />,]
                                
                            }
                        >
                            <Skeleton loading={loading} avatar active>
                                <Meta
                                    avatar={<Avatar>{atendimento?.status?.charAt(0)}</Avatar>}
                                    title={atendimento?.status}
                                    description={
                                    <>
                                        {[
                                            atendimento.status,
                                            atendimento.status,
                                            atendimento.status,
                                        ].filter(Boolean)?.join(", ")
                                        }{" "}-{" "}
                                        <strong>{atendimento.status}</strong>
                                    </>
                                }
                                />
                            </Skeleton>
                        </Card>
                    </Col>
                ))}
            </Row>
        )}

        <Row justify="end">
            <Col>
                <Pagination
                    size="small"
                    showSizeChanger={true}
                    pageSizeOptions={OPCOES_TAMANHO_PAGINA}
                    defaultPageSize={paginacao.size}
                    total={paginacao.totalElements}
                    showTotal={(total) => <strong>{total} atendimentos no total</strong>}
                    current={paginacao.currentPage + 1}
                    onChange={(page, size) => {
                        setFilter({
                          ...filter,
                          page: filter.size !== size ? 0 : page - 1,
                          size,
                        });
                    }}
                />
            </Col>
        </Row> 
    </>
);
};