import { Button } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const HeaderComponent = ({titulo, tituloButao, infoHeader, urlCadastro }) => {
    return (
        <>
          <div>
            <h2>{titulo}</h2>
            {infoHeader}
          </div>

          {tituloButao && (
            <div id="header-actions">
              <Link to={urlCadastro} className="button-link">
                <Button type="primary" style={{ width: "160px" }}>
                  {tituloButao}
                </Button>
              </Link>
            </div>
          )}
      </>
    );
} 