import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { mask, unMask } from "remask";
import { cpf } from "cpf-cnpj-validator";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { openNotification } from "../../../components/Notification";
import { UserContext } from "../../../contexts/UserContext";
import { CEP_MASK, CPF_MASK, PESSOA_SELECT_PADRAO, TELEFONE_MASK, urls, USER_ROLES } from "../../../utils";
import { rendaService } from "../../../services/resources/rendaService";
import { religiaoService } from "../../../services/resources/religiaoService";
import { ocupacaoService } from "../../../services/resources/ocupacaoService";
import { grauInstrucaoService } from "../../../services/resources/grauInstrucaoService";
import { faixaEtariaService } from "../../../services/resources/faixaEtariaService";
import { etniaService } from "../../../services/resources/etniaService";
import { sexualidadeService } from "../../../services/resources/sexualidadeService";
import { identidadeGeneroService } from "../../../services/resources/identidadeGeneroService";
import { generoService } from "../../../services/resources/generoService";
import { estadoCivilService } from "../../../services/resources/estadoCivilService";
import { deficienciaService } from "../../../services/resources/deficienciaService";
import { doencaService } from "../../../services/resources/doencaService";
import { enderecoService } from "../../../services/resources/enderecoService";
import { DATA_FORMATO } from "../../../utils";
import { pessoaService } from "../../../services/resources/pessoaService";
import { useContext, useEffect, useState } from "react";

export const FormularioPessoa = ({ onView, onEdit, onCreate, afterCreate, codigoPessoa }) => {

    const history = useHistory();

    const { estados } = useContext(UserContext);

    const [pessoaForm] = Form.useForm();

    const [cidades, setCidades] = useState([]);
    const [bairros, setBairros] = useState([]);

    const [generos, setGeneros] = useState([]);
    const [identidadeGenero, setIdentidadeGenero] = useState([]);
    const [sexualidade, setSexualidade] = useState([]);
    const [renda, setRenda] = useState([]);
    const [religiao, setReligiao] = useState([]);
    const [etnias, setEtnias] = useState([]);
    const [statusCivil, setStatusCivil] = useState([]);
    const [doencas, setDoencas] = useState([]);
    const [deficiencias, setDeficiencias] = useState([]);
    const [faixaEtarias, setFaixaEtarias] = useState([]);
    const [grausIntrucao, setGrausInstrucao] = useState([]);
    const [ocupacoes, setOcupacoes] = useState([]);

    const [loading, setLoading] = useState({
        submit: false,
    });

    useEffect(() => {
    !onCreate && getPessoa();

    getDoencas();
    getDeficiencias();
    getStatusCivil();
    getEtnias();
    getGeneros();
    getIdentidadeGenero();
    getSexualidade();
    getRenda();
    getReligiao();
    getOcupacoes();
    getFaixasEstarias();
    getGrausInstrucao();
    // eslint-disable-next-line
    }, []);

    async function getPessoa() {
        setLoading(true);
        try {
            const { data: pessoa } = await pessoaService.buscar(codigoPessoa);
            getCidades(pessoa?.endereco?.bairro?.cidade?.estado?.id);
            getBairros(pessoa?.endereco?.bairro?.cidade?.id);

            pessoaForm.setFieldsValue({
            ...pessoa,
            dataNascimento:
                pessoa.dataNascimento &&
                moment(new Date(pessoa.dataNascimento), DATA_FORMATO),
            });
        } catch (error) {
            openNotification(
            "error",
            <strong>Ocorreu um erro ao carregar essa pessoa!</strong>,
            error?.response?.data?.detail
            );
            history.push(urls.PESSOAS_LIST);
        } finally {
            setLoading(false);
        }
    }

    async function getBairros(cidadeId) {
        (await cidadeId) &&
          enderecoService
            .getDistrictsCitys(cidadeId)
            .then((response) => {
              setBairros(response.data);
            })
            .catch((reason) =>
              openNotification(
                "error",
                <strong>Ocorreu um erro ao carregar as bairros!</strong>,
                reason?.response?.data?.detail
              )
            );
    }

    async function getFaixasEstarias() {
        await faixaEtariaService
          .loadFaixaEtarias()
          .then((response) => {
            setFaixaEtarias(response.data);
            pessoaForm.setFieldsValue({
                faixaEtaria: getCodigoSelectPadrao(response.data),
            });
            })
          .catch((reason) =>
            openNotification(
              "error",
              <strong>Ocorreu um erro ao buscar os tipos de Faixa Etária!</strong>,
              reason?.response?.data?.detail
            )
          );
    }

    async function getOcupacoes() {
        await ocupacaoService
          .loadOcupacoes()
          .then((response) => {
            setOcupacoes(response.data);
            pessoaForm.setFieldsValue({
                ocupacao: getCodigoSelectPadrao(response.data),
            });
            })
          .catch((reason) =>
            openNotification(
              "error",
              <strong>Ocorreu um erro ao buscar os tipos de Ocupações!</strong>,
              reason?.response?.data?.detail
            )
          );
    }

    async function getGrausInstrucao() {
        await grauInstrucaoService
          .loadGrausInstrucao()
          .then((response) => {
            setGrausInstrucao(response.data);
            pessoaForm.setFieldsValue({
                grauInstrucao: getCodigoSelectPadrao(response.data),
            });
        })
          .catch((reason) =>
            openNotification(
              "error",
              <strong>
                Ocorreu um erro ao buscar os tipos de Grau de Instrução!
              </strong>,
              reason?.response?.data?.detail
            )
          );
    }
    
    async function getGeneros() {
        await generoService
        .loadGeneros()
        .then((response) => {
            setGeneros(response.data);
            pessoaForm.setFieldsValue({
                genero: getCodigoSelectPadrao(generos),
            });
        })
        .catch((reason) =>
        openNotification(
            "error",
            <strong>Ocorreu um erro ao buscar os tipos de Sexo!</strong>,
            reason?.response?.data?.detail
        )
        );
    }

    async function getIdentidadeGenero() {
        await identidadeGeneroService
            .loadIdentidadeGenero()
            .then((response) => {
                setIdentidadeGenero(response.data);
                pessoaForm.setFieldsValue({
                    identidadeGenero: getCodigoSelectPadrao(response.data),
                });
            })
            .catch((reason) =>
            openNotification(
                "error",
                <strong>
                Ocorreu um erro ao buscar a lista de identidade de gênero!
                </strong>,
                reason?.response?.data?.detail
            )
            );
    }

    async function getSexualidade() {
    await sexualidadeService
        .load()
        .then((response) => {
            setSexualidade(response.data);
            pessoaForm.setFieldsValue({
                sexualidade: getCodigoSelectPadrao(response.data),
            });
        })
        .catch((reason) =>
        openNotification(
            "error",
            <strong>Ocorreu um erro ao buscar a lista de Orientações Sexuais!</strong>,
            reason?.response?.data?.detail
        )
        );
    }

    async function getRenda() {
    await rendaService
        .load()
        .then((response) => {
            setRenda(response.data);
            pessoaForm.setFieldsValue({
                renda: getCodigoSelectPadrao(response.data),
            });
        })
        .catch((reason) =>
        openNotification(
            "error",
            <strong>Ocorreu um erro ao buscar a lista de rendas!</strong>,
            reason?.response?.data?.detail
        )
        );
    }

    async function getReligiao() {
    await religiaoService
        .load()
        .then((response) => {
            setReligiao(response.data);
            pessoaForm.setFieldsValue({
                religiao: getCodigoSelectPadrao(response.data),
            });
        })
        .catch((reason) =>
        openNotification(
            "error",
            <strong>Ocorreu um erro ao buscar a lista de religiões!</strong>,
            reason?.response?.data?.detail
        )
        );
    }

    async function getEtnias() {
    await etniaService
        .loadEtnias()
        .then((response) => {
            setEtnias(response.data);
            pessoaForm.setFieldsValue({
                racaCor: getCodigoSelectPadrao(response.data),
            });
        })
        .catch((reason) =>
        openNotification(
            "error",
            <strong>Ocorreu um erro ao buscar os tipos de Etnia!</strong>,
            reason?.response?.data?.detail
        )
        );
    }

    async function getStatusCivil() {
    await estadoCivilService
        .loadStatusCivil()
        .then((response) => {
            setStatusCivil(response.data);
            pessoaForm.setFieldsValue({
                estadoCivil: getCodigoSelectPadrao(response.data),
            });
        })
        .catch((reason) =>
        openNotification(
            "error",
            <strong>Ocorreu um erro ao buscar os Estados Civis!</strong>,
            reason?.response?.data?.detail
        )
        );
    }

    async function getDoencas() {
    await doencaService
        .list()
        .then((response) => {
            setDoencas(response.data);
            pessoaForm.setFieldsValue({
                doencaRara: getCodigoSelectPadrao(response.data),
            });
        })
        .catch((reason) =>
        openNotification(
            "error",
            <strong>Ocorreu um erro ao buscar os tipos de doenças!</strong>,
            reason?.response?.data?.detail
        )
        );
    }

    async function getDeficiencias() {
    await deficienciaService
        .loadDeficiencias()
        .then((response) => {
            setDeficiencias(response.data);
            pessoaForm.setFieldsValue({
                deficiencia: getCodigoSelectPadrao(response.data),
            });
        })
        .catch((reason) =>
        openNotification(
            "error",
            <strong>Ocorreu um erro ao buscar os tipos de Deficiência!</strong>,
            reason?.response?.data?.detail
        )
        );
    }

    async function getCidades(estadoId) {
    await enderecoService
        .getCitysState(estadoId)
        .then((response) => {
        setCidades(response.data);
        })
        .catch((reason) =>
        openNotification(
            "error",
            <strong>Ocorreu um erro ao carregar as cidades!</strong>,
            "Erro: " + reason?.response?.data?.detail
        )
        );
    }

     // Função para aplicar as mascaras nos campos que precisam
    function onChangeItem(name, value, patterns) {
        pessoaForm.setFieldsValue({
        [name]: mask(unMask(value), patterns),
        });
    }

    function onChangeCep(value) {
        pessoaForm.setFieldsValue({
        endereco: { cep: mask(unMask(value), CEP_MASK) },
        });
    }

  const validarCPF = () => {
    const numeroCPF = pessoaForm.getFieldValue("cpf");

    if (!numeroCPF || numeroCPF.length === 14) {
      if (!numeroCPF || cpf.isValid(numeroCPF)) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error("O cpf informado não é válido pelo digito verificador!")
      );
    }
  };

  async function handleSelectEstado(value, mantemCidades) {
    if (!mantemCidades)
      pessoaForm.setFieldsValue({
        endereco: { bairro: { id: null, cidade: { id: null } } },
      });
    value && (await getCidades(value));
  }

  async function handleSelectPersonCity(value, mantemBairros) {
    if (!mantemBairros)
      pessoaForm.setFieldsValue({ endereco: { bairro: { id: null } } });
    value && (await getBairros(value));
  }

  function getCodigoSelectPadrao(lista) {
    return {codigo: lista?.filter((x) => x?.descricao === PESSOA_SELECT_PADRAO)[0].codigo};
  }

  async function handleSubmit() {
    setLoading({ ...loading, submit: true });

    pessoaForm.validateFields().then(() => {
        pessoaService
          .adicionar(pessoaForm.getFieldsValue())
          .then((response) => {
            afterCreate(response.data);
            pessoaForm.resetFields();
            openNotification(
              "success",
              <strong>Pessoa criada com sucesso!</strong>,
              "Essa pessoa foi criada com sucesso!"
            );
          })
          .catch((reason) => {
            openNotification(
              "error",
              <strong>Erro ao criar essa pessoa!</strong>,
              reason?.response?.data?.detail
            );
          })
          .finally(() => setLoading({ ...loading, submit: false }));
      });
  }


    return (
        <>
            <Form
                form={pessoaForm}
                name="pessoa"
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item
                            name="nome"
                            label={<span>Nome completo:</span>}
                            rules={[{ required: true, message: "Escrever o nome completo é obrigatório!" }]}
                        >
                        <Input
                            placeholder="Escreva o nome completo"
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item name="nomeSocial" label={<span>Nome social:</span>}>
                        <Input
                            type="text"
                            placeholder="Escreva o nome social"
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item name="nomeMae" label={<span>Nome da mãe:</span>}>
                        <Input
                            placeholder="Escreva o nome da mãe"
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                            name="dataNascimento"
                            label={<span>Data de nascimento:</span>}
                        >
                        <DatePicker
                            style={{ width: "100%" }}
                            format={DATA_FORMATO}
                            placeholder="Formato dd/mm/aaaa"
                            disabledDate={(d) => !d || d.isAfter(new Date())}
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item name="rg" label={<span>RG:</span>}>
                        <Input
                            placeholder="Escreva o número do seu RG"
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                            name="cpf"
                            label={<span>CPF:</span>}
                            onChange={({ target }) =>
                                onChangeItem("cpf", target.value, CPF_MASK)
                            }
                            rules={[
                                {
                                    validator: validarCPF,
                                },
                            ]}
                        >
                        <Input
                            placeholder="Escreva o número do seu CPF"
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item name="nis" label={<span>NIS:</span>}>
                        <Input
                            type="text"
                            placeholder="Escreva o número do seu nis"
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item
                            name={["genero", "codigo"]}
                            label={<span>Sexo:</span>}
                            rules={[{ required: true, message: "Selecionar o sexo que você se identifica é obrigatório!" }]}
                        >
                        <Select
                            disabled={onView}
                            placeholder="Selecione o sexo que você se identifica"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={generos?.map(({ descricao, codigo }) => ({
                                label: descricao,
                                value: codigo,
                            }))}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item
                            name={["identidadeGenero", "codigo"]}
                            label={<span>Identidade de gênero:</span>}
                        >
                        <Select
                            disabled={onView}
                            placeholder="Selecione a identidade de gênero que você se identifica"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={identidadeGenero?.map(({ descricao, codigo }) => ({
                            label: descricao,
                            value: codigo,
                            }))}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item
                        name={["sexualidade", "codigo"]}
                        label={<span>Orientação Sexual:</span>}
                        >
                        <Select
                            disabled={onView}
                            placeholder="Selecione a orientação sexual que você se identifica"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={sexualidade?.map(({ descricao, codigo }) => ({
                            label: descricao,
                            value: codigo,
                            }))}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item
                            name={["faixaEtaria", "codigo"]}
                            label={<span>Faixa etária:</span>}
                        >
                        <Select
                            placeholder="Selecione sua faixa etária"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={faixaEtarias?.map(({ descricao, codigo }) => ({
                            label: descricao,
                            value: codigo,
                            }))}
                            disabled={onView}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item
                            name={["estadoCivil", "codigo"]}
                            label={<span>Estado civil:</span>}
                        >
                        <Select
                            placeholder="Selecione seu estado civil"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={statusCivil?.map(({ descricao, codigo }) => ({
                            label: descricao,
                            value: codigo,
                            }))}
                            disabled={onView}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item
                            name={["racaCor", "codigo"]}
                            label={<span>Raça/Cor:</span>}
                        >
                        <Select
                            placeholder="Selecione sua raça e cor"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={etnias?.map(({ descricao, codigo }) => ({
                            label: descricao,
                            value: codigo,
                            }))}
                            disabled={onView}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                            name={["renda", "codigo"]}
                            label={<span>Faixa de renda familiar:</span>}
                        >
                        <Select
                            placeholder="Selecione sua faixa de renda familiar"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={renda?.map(({ descricao, codigo }) => ({
                            label: descricao,
                            value: codigo,
                            }))}
                            disabled={onView}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                        name={["religiao", "codigo"]}
                        label={<span>Religião:</span>}
                        >
                        <Select
                            placeholder="Escolha a religião que você se identifica"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={religiao?.map(({ descricao, codigo }) => ({
                            label: descricao,
                            value: codigo,
                            }))}
                            disabled={onView}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item name="nacionalidade" label={<span>Nacionalidade:</span>}>
                        <Input
                            placeholder="Escolha sua nacionalidade"
                            name="personNacionalidade"
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item name="naturalidade" label={<span>Naturalidade:</span>}>
                        <Input
                            placeholder="Escolha sua naturalidade"
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                            name={["ocupacao", "codigo"]}
                            label={<span>Ocupação:</span>}
                        >
                        <Select
                            placeholder="Escolha sua ocupação profissional"
                            showSearch
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={ocupacoes?.map(({ descricao, codigo }) => ({
                                label: descricao,
                                value: codigo,
                            }))}
                            disabled={onView}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                            name={["grauInstrucao", "codigo"]}
                            label={<span>Grau de Instrução:</span>}
                        >
                        <Select
                            placeholder="Escolha seu grau de instrução escolar."
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={grausIntrucao?.map(({ descricao, codigo }) => ({
                                label: descricao,
                                value: codigo,
                            }))}
                            disabled={onView}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                            name={["deficiencia", "codigo"]}
                            label={<span>Pessoa com deficiência?</span>}
                            defaultValue={null}
                        >
                        <Select
                            placeholder="Escolha uma deficiência, caso não tenha uma, escolha a opção não."
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={deficiencias?.map(({ descricao, codigo }) => ({
                                label: descricao,
                                value: codigo,
                            }))}
                            disabled={onView}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                        name={["doencaRara", "codigo"]}
                        label={<span>Doença rara?</span>}
                        >
                        <Select
                            placeholder="Escolha uma doença rara, caso não tenha uma, escolha a opção não."
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={doencas?.map(({ descricao, codigo }) => ({
                                label: descricao,
                                value: codigo,
                            }))}
                            disabled={onView}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item
                            label={<span>Estado:</span>}
                            name={["endereco", "bairro", "id"]}
                            rules={[{ required: true, message: "Selecionar o esdado que você mora é obrigatório!" }]}
                        >
                        <Select
                            placeholder="Selecione o estado que você mora"
                            onChange={(estado) => handleSelectEstado(estado, false)}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={estados?.map(({ nome, id }) => ({
                                label: nome,
                                value: id,
                            }))}
                            disabled={onView}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item
                            name={["endereco", "bairro", "id"]}
                            label={<span>Município:</span>}
                            rules={[{ required: true, message: "Selecionar o município que você mora é obrigatório!" }]}
                        >
                        <Select
                            placeholder="Selecione o município que você mora."
                            onChange={(cidade) => handleSelectPersonCity(cidade, false)}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={cidades?.map(({ nome, id }) => ({
                                label: nome,
                                value: id,
                            }))}
                            disabled={onView}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item
                            name={["endereco", "bairro", "id"]}
                            label={<span>Bairro:</span>}
                            rules={[{ required: true, message: "Selecionar o bairro que você mora é obrigatório!" }]}
                        >
                        <Select
                            placeholder="Selecione o bairro que você mora."
                            disabled={onView}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={bairros?.map(({ nome, id }) => ({
                                label: nome,
                                value: id,
                            }))}
                            autoComplete="none"
                        />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            name={["endereco", "logradouro"]}
                            label={<span>Logradouro:</span>}
                        >
                        <Input
                            placeholder="Escreva o nome do logradouro de onde você mora."
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                        name={["endereco", "numero"]}
                        label={<span>Número:</span>}
                        >
                        <Input
                            placeholder="Escreva o número da casa de onde você mora."
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                        <Form.Item
                            name={["endereco", "cep"]}
                            label={<span>CEP:</span>}
                            onChange={({ target }) => onChangeCep(target.value)}
                        >
                        <Input
                            placeholder="Escreva o cep da rua que você mora."
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            name={["endereco", "complemento"]}
                            label={<span>Ponto de referência</span>}
                        >
                        <Input
                            placeholder="Escreva um ponto de referência que fica perto onde você mora."
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item
                            label={<span>Telefone 01:</span>}
                            name="telefone1"
                            onChange={({ target }) =>
                                onChangeItem("telefone1", target.value, TELEFONE_MASK)
                            }
                        >
                        <Input
                            autoComplete="none"
                            disabled={onView}
                            placeholder="Escreva seu número de telefone."
                            allowClear
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item
                            label={<span>Telefone 02:</span>}
                            name="telefone2"
                            onChange={({ target }) =>
                                onChangeItem("telefone2", target.value, TELEFONE_MASK)
                            }
                        >
                        <Input
                            autoComplete="none"
                            disabled={onView}
                            placeholder="Escreva outro número de telefone."
                            allowClear
                        />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Form.Item
                        name="email"
                        label={<span>E-mail:</span>}
                        rules={[{ type: "email", message: "O e-mail que você escreveu está válido." }]}
                        >
                        <Input
                            placeholder="Escreva seu e-mail."
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            name="observacao"
                            label={<span>Observação:</span>}
                        >
                        <Input
                            placeholder="Escreva alguma observação sobre a pessoa."
                            autoComplete="none"
                            disabled={onView}
                        />
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="end">
                    {!onView && (
                        <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading.submit}>
                            {onEdit ? (
                            <span>salvar edições</span>
                            ) : (
                            <span>cadastrar pessoa</span>
                            )}
                        </Button>
                        </Form.Item>
                    )}
                </Row>
            </Form>
        </>
    );
};