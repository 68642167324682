import api from "../api";

export const canalAtendimentoService = {
    async listar() {
        return await api.get('/canais-atendimento');
    },

    async listarPorCanal(codigoCanal) {
        return await api.get(`/canais-atendimento/${codigoCanal}`);
    },
};